import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './Partners.module.scss';

const Partners = () => {
	return (
		<section className={styles.Partners}>
			<StaticImage
				src="../../../assets/images/scorefam.png"
				alt="scorefam"
				className={styles.Partners_logo}
				objectFit="contain"
			/>
			<StaticImage
				src="../../../assets/images/wirepay.png"
				alt="wirepay"
				className={styles.Partners_logo}
				objectFit="contain"
			/>
			<StaticImage
				src="../../../assets/images/myyinvest.png"
				alt="myyinvest"
				className={styles.Partners_logo}
				objectFit="contain"
			/>
			<StaticImage
				src="../../../assets/images/homerunner.png"
				alt="homerunner"
				className={styles.Partners_logo}
				objectFit="contain"
			/>
		</section>
	);
};

export default Partners;

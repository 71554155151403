import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const MobileBanner: React.FC = () => {
	return (
		<StaticImage
			src="../../assets/images/mobile-banner.png"
			alt="Dashboard"
			className="cta-mobile-banner"
			objectFit="contain"
			placeholder="blurred"
		/>
	);
};

export default MobileBanner;

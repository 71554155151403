import React from 'react';
import { BRASS_FEATURES } from 'data';

import * as styles from './Features.module.scss';

const Features: React.FC = () => {
	return (
		<section className={styles.Features}>
			{BRASS_FEATURES.map(feature => {
				return (
					<div
						className={styles.Features_feature_container}
						key={feature.label}
					>
						<feature.icon />
						<div className={styles.Features_feature_textBox}>
							<p className={styles.Features_feature_textBox_label}>
								{feature.label}
							</p>
							<p className={styles.Features_feature_textBox_description}>
								{feature.description}
							</p>
						</div>
					</div>
				);
			})}
		</section>
	);
};

export default Features;

import React from 'react';
import cx from 'classnames';

import Spinner from '../Spinner';

import * as styles from './Button.module.scss';

export interface BaseButtonProps {
	variant?:
		| 'primary'
		| 'secondary'
		| 'primary-outline'
		| 'danger-outline'
		| 'text'
		| 'success';
	icon?: React.ReactNode;
	size?: 'S' | 'M' | 'L';
	label?: string;
	loading?: boolean | { delay?: number };
	prefixCls?: string;
	className?: string;
	shadow?: boolean;
	rounded?: boolean;
	block?: boolean;
	danger?: boolean;
	disabled?: boolean;
	children?: React.ReactNode;
	type?: 'submit' | 'reset' | 'button';
	onClick?: () => void;
	as?: any;
}

const Button: React.FC<BaseButtonProps> = props => {
	const {
		as: ContainerComponent,
		block,
		className,
		loading,
		rounded,
		shadow,
		type,
		label,
		danger,
		variant,
		...rest
	} = props;

	const classes = cx(
		styles.Button,
		{
			[styles.Button_block]: block,
			[styles.Button_danger]: danger,
			[styles.Button__disabled]: props.disabled && !loading,
			[styles.Button__loading]: loading,
			[styles.Button_rounded]: rounded,
			[styles.Button_shadow]: shadow,
			[styles[`Button_${variant}`]]: variant,
			[styles[`Button_${variant}__disabled`]]: props.disabled,
		},
		className
	);

	return (
		<ContainerComponent type={type} className={classes} {...rest}>
			{loading && (
				<Spinner
					className={cx(styles.Loader, {
						[styles[`Button_${variant}__loading`]]: loading,
					})}
				/>
			)}
			<span
				className={cx(styles.Button_content, {
					[styles.Button_content__loading]: loading,
				})}
			>
				{label || [props.children]}
			</span>
		</ContainerComponent>
	);
};

Button.defaultProps = {
	type: 'button',
	as: 'button',
};

export default Button;

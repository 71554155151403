import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Banner: React.FC = () => {
	return (
		<StaticImage
			src="../../assets/images/banner.png"
			alt="Dashboard"
			className="cta-banner"
			objectFit="contain"
			placeholder="blurred"
		/>
	);
};

export default Banner;

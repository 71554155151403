import React from 'react';
import Button from 'components/utils/Button';
import TextInput from 'components/utils/TextInput';

import { Banner, MobileBanner } from 'components/images';
import * as styles from './Cta.module.scss';
import { IndexPageState, IndexPageStateUpdate } from 'types';

interface CtaProps {
	handleStateUpdate: IndexPageStateUpdate;
	state: IndexPageState;
	handleSubmit: VoidFunction;
}

const Cta: React.FC<CtaProps> = ({
	handleStateUpdate,
	state,
	handleSubmit,
}) => {
	return (
		<section className={styles.Cta}>
			<MobileBanner />
			<div className={styles.Cta_textBlock}>
				<h1 className={styles.Cta_heading}>You run the business, we bank it</h1>
				<p className={styles.Cta_subText}>
					Brass provides all kinds of businesses with simple and powerful
					<br className={styles.Cta_subText_break} /> banking services to enable
					their business growth.
				</p>
			</div>
			<div className={styles.Cta_form}>
				<TextInput
					className={styles.Cta_form_input}
					name="email"
					placeholder="Enter your email address"
					value={state.email}
					errorMessage={
						state.email && !state.isEmailValid
							? 'Please enter a valid email'
							: undefined
					}
					onChange={({ target: { value: email } }) =>
						handleStateUpdate({ email })
					}
				/>
				<Button
					loading={state.loading}
					className={styles.Cta_form_action}
					variant="primary"
					onClick={handleSubmit}
				>
					Get started for free
				</Button>
			</div>
			<Banner />
		</section>
	);
};

export default Cta;

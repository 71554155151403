// eslint-disable-next-line no-unused-vars
import React, { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import cx from 'classnames';

import * as styles from './TextInput.module.scss';

export interface TextInputProps
	extends InputHTMLAttributes<any>,
		TextareaHTMLAttributes<any> {
	label?: React.ReactNode;
	labelPosition?: 'top' | 'left';
	isMultiline?: boolean;
	isReadOnly?: boolean;
	isDisabled?: boolean;
	warningMessage?: string;
	errorMessage?: string;
}

const TextInput: React.FC<TextInputProps> = ({
	errorMessage,
	warningMessage,
	className,
	label,
	labelPosition,
	isMultiline,
	isDisabled,
	isReadOnly,
	...props
}) => {
	return (
		<div
			className={cx(
				styles.TextInput,
				{
					[styles.TextInput_with_warning]: warningMessage && !errorMessage,
					[styles.TextInput_with_error]: errorMessage,
					[styles.TextInput_layout_left]: labelPosition === 'left',
				},
				className
			)}
		>
			<label htmlFor={props.id} className={styles.TextInput_label}>
				{label}
			</label>
			<div className={cx(styles.TextInput_wrapper)}>
				{isMultiline ? (
					<textarea
						{...props}
						data-testid="textarea"
						className={cx(styles.TextInput_textarea)}
						disabled={Boolean(isDisabled)}
						readOnly={Boolean(isReadOnly)}
					/>
				) : (
					<input
						{...props}
						data-testid="input"
						className={cx(styles.TextInput_input)}
						disabled={Boolean(isDisabled)}
						readOnly={Boolean(isReadOnly)}
					/>
				)}

				{(errorMessage || warningMessage) && (
					<p className={styles.TextInput_alert_msg}>
						{errorMessage || warningMessage}
					</p>
				)}
			</div>
		</div>
	);
};

TextInput.defaultProps = {
	type: 'text',
	labelPosition: 'top',
	isMultiline: false,
	isReadOnly: false,
	isDisabled: false,
};

export default TextInput;

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Support: React.FC = () => {
	return (
		<StaticImage
			src="../../assets/images/support.png"
			alt="Support"
			className="feature-support"
			objectFit="contain"
			placeholder="blurred"
		/>
	);
};

export default Support;

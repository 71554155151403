import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const MyyinvestTestimonial: React.FC = () => {
	return (
		<StaticImage
			src="../../assets/images/myyinvest-testimonial.png"
			alt="myyinvest-testimonial"
			className="myyinvest-testimonial"
			objectFit="contain"
			placeholder="blurred"
		/>
	);
};

export default MyyinvestTestimonial;

import {
	WirepayTestimonial,
	MyyinvestTestimonial,
	HomeRunnerTestimonial,
	ScorefamTestimonial,
} from 'components/images';

export const BRASS_TESTIMONIALS = [
	{
		image: WirepayTestimonial,
		author: 'Miracle Anyanwu, Wirepay',
		testimonial: `“I think it’s one of the easiest things we’ve done since we started this company, on-boarding to Brass is one of the easiest things. Very very good and fast, everything was done under a couple of hours”`,
	},
	{
		image: ScorefamTestimonial,
		author: 'Simisoluwa Adeyemo, Scorefam',
		testimonial: `“I chose Brass for my business because it is trusted and reliable. I like the fact that transactions are free. I heard about Brass from a friend of mine and the idea of an online bank managing my company funds is okay with me. So far, I've liked using Brass”`,
	},
	{
		image: HomeRunnerTestimonial,
		author: 'Eyimisan Abusomwan, Founder Homerunner',
		testimonial: `“There is a way Brass is set up, starting from the welcome email, to the call I received. It was so friendly. I feel like that experience alone made me use it. I already signed up on other platforms  but I saw a reflection of my own company. I am trying to solve a problem in my own space and Brass is trying to solve my own problem. I feel like thats the greatest thing”`,
	},
	{
		image: MyyinvestTestimonial,
		author: 'Adebisi Borokinni, Co-founder Myy Invest',
		testimonial: `“There is an ease that Brass gives. From when you think about a business to how you move your money, Brass is on that journey with you and you don't worry too much”`,
	},
];

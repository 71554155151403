import React from 'react';
import cx from 'classnames';
import { ChevronRight, ChevronLeft } from 'react-feather';
import Button from 'components/utils/Button';
import { BRASS_TESTIMONIALS } from 'data/testimonials';
import * as styles from './Testimonials.module.scss';
import { HandleSignupRedirect } from 'types';

interface TestimonialsProps {
	handleSignupRedirect: HandleSignupRedirect;
}

const Testimonials: React.FC<TestimonialsProps> = ({
	handleSignupRedirect,
}) => {
	const [activeIndex, setActiveIndex] = React.useState(0);
	const [nextIndex, setNextIndex] = React.useState(0);
	const testimonialRef = React.useRef<HTMLDivElement>(null);
	const Image = BRASS_TESTIMONIALS[nextIndex].image;

	const animationCallback = React.useCallback(() => {
		const testimonial = testimonialRef.current;
		if (testimonial) {
			const originalClassName = testimonial.className.split(' ')[0];
			testimonial.setAttribute('class', originalClassName);
			setTimeout(() => {
				setNextIndex(activeIndex);
				testimonial.setAttribute(
					'class',
					`${originalClassName} testimonial-show`
				);
			}, 350);
		}
	}, [activeIndex]);

	React.useEffect(() => {
		animationCallback();
	}, [activeIndex]);

	return (
		<section className={styles.Testimonials}>
			<div
				ref={testimonialRef}
				className={styles.Testimonials_testimonial_container}
			>
				<p className={styles.Testimonials_testimonial_text}>
					{BRASS_TESTIMONIALS[nextIndex].testimonial}
				</p>
				<p className={styles.Testimonials_testimonial_author}>
					{BRASS_TESTIMONIALS[nextIndex].author}
				</p>
				<Image />
			</div>
			<Button
				onClick={() => handleSignupRedirect()}
				className={styles.Testimonials_getStarted}
				variant="primary"
			>
				Get started for free
			</Button>
			{nextIndex > 0 && nextIndex < BRASS_TESTIMONIALS.length && (
				<button
					className={cx(styles.Testimonials_nav, styles.Testimonials_nav_left)}
					onClick={() => setActiveIndex(activeIndex - 1)}
					aria-label="Left Navigation"
				>
					<ChevronLeft />
				</button>
			)}
			{nextIndex < BRASS_TESTIMONIALS.length - 1 && (
				<button
					className={cx(styles.Testimonials_nav, styles.Testimonials_nav_right)}
					onClick={() => setActiveIndex(activeIndex + 1)}
					aria-label="Right Navigation"
				>
					<ChevronRight />
				</button>
			)}
		</section>
	);
};

export default Testimonials;

import { isEmpty, isPlainObject, transform } from 'lodash';

export const truncate = (text = '', length: number) =>
	text ? (text.length <= length ? text : `${text.substr(0, length)}...`) : '';

export function clean<T = any>(object: T) {
	return transform<any, T>(object, (result, value, key) => {
		// Recurse into arrays and objects.
		if (Array.isArray(value) || isPlainObject(value)) {
			value = clean(value);
		}

		// Exclude empty objects.
		if (isPlainObject(value) && isEmpty(value)) {
			return;
		}

		// Exclude empty arrays.
		if (Array.isArray(value) && !value.length) {
			return;
		}

		// Exclude empty strings.
		if (value === '') {
			return;
		}

		// Exclude NaN values.
		if (Number.isNaN(value)) {
			return;
		}

		// Exclude null values.
		if (value === null) {
			return;
		}

		// Exclude undefined values.
		if (value === undefined) {
			return;
		}

		// Append when recursing arrays.
		if (Array.isArray(result)) {
			return result.push(value);
		}

		result[key] = value;
	});
}

import { window } from 'browser-monads';
import { isEmpty, omit, pick } from 'lodash';
import * as config from 'config';
import { clean } from 'utils/genericUtils';

type webMessengerPayload = {
	externalId: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string;
	customerName: string;
};

let restoreIdStorageKey: string;

const initializeFreshChat = async (payload?: webMessengerPayload) => {
	payload = clean(payload);
	let restoreIdStorageKey: string;
	let restoreId: string | null = null;

	if (!isEmpty(payload)) {
		restoreIdStorageKey = !!payload
			? `con_${payload.externalId.split('_')[1]}`
			: '';
		restoreId = localStorage.getItem(restoreIdStorageKey);
	}

	window.fcWidget.init({
		token: config.FRESHCHAT_TOKEN,
		host: config.FRESHCHAT_HOST,
		...pick(payload, ['externalId']),
		...(!!restoreId && { restoreId }),
		config: {
			headerProperty: {
				hideChatButton: false,
			},
		},
	});

	window.fcWidget.user.get(function (resp: any) {
		const status = resp && resp.status;
		if (status !== 200) {
			window.fcWidget.user.setProperties({
				...omit(payload, ['externalId']),
			});
			window.fcWidget.on('user:created', function (resp: any) {
				const status = resp && resp.status;
				const data = resp && resp.data;
				if (status === 200) {
					if (data.restoreId) {
						localStorage.setItem(restoreIdStorageKey, data.restoreId);
					}
				}
			});
		}
	});
};

export const initialize = (payload?: webMessengerPayload) => {
	if (
		!config.FRESHCHAT_HOST &&
		!config.FRESHCHAT_TOKEN &&
		!config.FRESHCHAT_WIDGET_URL
	) {
		throw Error(
			'Missing environment variables for Freshchat token, host and widget URL'
		);
	}
	if (!window.fcWidget || !window.fcWidget.isLoaded()) {
		const head = document.getElementsByTagName('head')[0];
		const scriptElem: any = document.createElement('script');
		scriptElem.async = true;
		scriptElem.src = config.FRESHCHAT_WIDGET_URL;
		scriptElem.onload = () => initializeFreshChat(payload);
		head.appendChild(scriptElem);
	}
};

export const show = () => {
	window?.fcWidget?.open();
};

export const hide = () => {
	window?.fcWidget?.close();
};

export const destroy = () => {
	window?.fcWidget?.destroy();
};

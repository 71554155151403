import { window } from 'browser-monads';
import { CHANNEL, linkToDashboardSignup } from 'data';
import { IObject } from 'types';

export const goToSignUpPage = (params?: IObject<string>) => {
	const searchParams = new URLSearchParams('');
	params &&
		Object.keys(params).map(
			key => params[key] && searchParams.set(key, String(params[key]))
		);
	if (!searchParams.get('referrer')) searchParams.set('referrer', CHANNEL);
	console.log(`searchParams.toString()`, searchParams.toString());
	const url = `${linkToDashboardSignup}?${searchParams.toString()}`;
	window.open(url, 'blank');
};

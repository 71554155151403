import axios from 'axios';
import * as Sentry from '@sentry/gatsby';

import * as config from 'config';

const Axios = axios.create({
	baseURL: config.API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

// Handling JWT Token
Axios.interceptors.request.use(
	req => {
		const APIkey = config.API_KEY;
		req.headers.Authorization = `Bearer ${APIkey}`;
		return req;
	},
	error => {
		Sentry.captureException(error);
		return Promise.reject(error);
	}
);

// 401 response interceptor
Axios.interceptors.response.use(
	response => {
		return response;
	},
	function (err) {
		Sentry.captureException(err);
		return Promise.reject(err);
	}
);

export default Axios;

import React from 'react';
import cx from 'classnames';
import Header from '../Header';

import * as webMessenger from 'helpers/webMessenger';
import * as styles from './Layout.module.scss';
import { HandleSignupRedirect } from 'types';

interface LayoutProps {
	className?: string;
	handleSignupRedirect: HandleSignupRedirect;
}

const Layout: React.FC<LayoutProps> = ({
	children,
	className,
	handleSignupRedirect,
}) => {
	React.useEffect(() => {
		webMessenger.initialize();
	}, []);
	return (
		<section className={cx(styles.Layout, className)}>
			<Header handleSignupRedirect={handleSignupRedirect} />
			<main className={styles.Layout_main}>{children}</main>
		</section>
	);
};

export default Layout;

import { Speed, Security, Support } from 'components/images';

export const BRASS_FEATURES = [
	{
		icon: Speed,
		label: 'Speed',
		description:
			'Open a commercial-grade current account from anywhere in 10 minutes',
	},
	{
		icon: Security,
		label: 'Security',
		description:
			'Bank-grade security protocols to protect your finances and fully insured by the NDIC',
	},
	{
		icon: Support,
		label: 'Support',
		description:
			'Get access to a trusted support team and resources whenever you want',
	},
];

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Speed: React.FC = () => {
	return (
		<StaticImage
			src="../../assets/images/speed.png"
			alt="Speed"
			className="feature-speed"
			objectFit="contain"
			placeholder="blurred"
		/>
	);
};

export default Speed;

import React from 'react';
import cx from 'classnames';
import Button from 'components/utils/Button';
import { BrassLogo } from 'assets/svgs';
import { Link } from 'gatsby';
import * as styles from './Header.module.scss';
import { HandleSignupRedirect } from 'types';

interface HeaderProps {
	className?: string;
	handleSignupRedirect: HandleSignupRedirect;
}

const Header: React.FC<HeaderProps> = ({ className, handleSignupRedirect }) => {
	return (
		<div className={cx(styles.Header, className)}>
			<Link
				aria-label="Brass Logo Link"
				className={styles.Header_logoLink}
				to="/"
			>
				<BrassLogo />
			</Link>
			<Button
				className={styles.Header_getStarted}
				variant="primary"
				onClick={() => handleSignupRedirect()}
			>
				Get started for free
			</Button>
		</div>
	);
};

export default Header;

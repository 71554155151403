// extracted by mini-css-extract-plugin
export var Button = "Button-module--Button--1ap75";
export var Button_danger = "Button-module--Button_danger--2nBlS";
export var Button_primary = "Button-module--Button_primary--2wcgc";
export var Button_primaryOutline = "Button-module--Button_primary-outline--1D6_i";
export var Button_secondary = "Button-module--Button_secondary--Ri2pt";
export var Button_content = "Button-module--Button_content--2pNAg";
export var Button_content__loading = "Button-module--Button_content__loading--U6B7N";
export var Button_danger__loading = "Button-module--Button_danger__loading--2R94Y";
export var Button_secondary__loading = "Button-module--Button_secondary__loading--1IIyb";
export var Button_text = "Button-module--Button_text--3hZ9W";
export var Button_text__disabled = "Button-module--Button_text__disabled--12Ywn";
export var Button_primaryOutline__disabled = "Button-module--Button_primary-outline__disabled--vgbM9";
export var Button_primaryOutline__loading = "Button-module--Button_primary-outline__loading--VDZG3";
export var Button_dangerOutline = "Button-module--Button_danger-outline--xxAna";
export var Button_dangerOutline__disabled = "Button-module--Button_danger-outline__disabled--3LO8g";
export var Button_dangerOutline__loading = "Button-module--Button_danger-outline__loading--18ucu";
export var Button__disabled = "Button-module--Button__disabled--201_O";
export var Button__loading = "Button-module--Button__loading--3cqpp";
export var Button_block = "Button-module--Button_block--3pDgY";
export var Button_shadow = "Button-module--Button_shadow--GL9Fg";
export var Button_rounded = "Button-module--Button_rounded--1jAB3";
export var Loader = "Button-module--Loader--1tSKC";
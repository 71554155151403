import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const ScorefamTestimonial: React.FC = () => {
	return (
		<StaticImage
			src="../../assets/images/scorefam-testimonial.png"
			alt="scorefam-testimonial"
			className="scorefam-testimonial"
			objectFit="contain"
			placeholder="blurred"
		/>
	);
};

export default ScorefamTestimonial;

import React from 'react';
import { string } from 'yup';
import { isBoolean } from 'lodash';
import { Layout } from 'layouts';
import SEO from 'components/utils/Seo';
import Cta from 'components/sections/Cta';
import Features from 'components/sections/Features';
import Testimonials from 'components/sections/Testimonials';
import Partners from 'components/sections/Partners';
import {
	HandleSignupRedirect,
	IndexPageState,
	IndexPageStateUpdate,
	IObject,
} from 'types';
import Axios from 'helpers/axios';
import { CHANNEL } from 'data';
import { goToSignUpPage } from 'utils/signUpUtils';
import { PageProps } from 'gatsby';

const IndexPage: React.FC<PageProps> = ({ location }) => {
	const searchParams = new URLSearchParams(location.search);
	const referrer = searchParams.get('referrer') || '';
	const [state, setState] = React.useState<IndexPageState>({
		loading: false,
		email: '',
		isEmailValid: null,
	});

	const handleStateUpdate: IndexPageStateUpdate = newState =>
		setState(state => ({ ...state, ...newState }));

	const handleSignupRedirect: HandleSignupRedirect = params =>
		goToSignUpPage({
			referrer,
			...params,
		});

	const subscribe = (data: IObject<string>) =>
		Axios.put('/marketing/subscribers', data);

	const handleSubmit = async () => {
		if (isBoolean(state.isEmailValid) && state.isEmailValid) {
			try {
				handleStateUpdate({ loading: true });
				await subscribe({
					email: state.email,
					channel: CHANNEL,
				});
			} catch (error) {
				console.log(`Error: Subscribing`);
			} finally {
				handleSignupRedirect({
					email: state.email,
				});
				handleStateUpdate({ loading: false });
			}
		}
	};

	const handleEmailValidity = async (
		email: string,
		cb: (valid: boolean) => void
	) => {
		const schema = string().email().nullable();
		const isValid = await schema.isValid(state.email);
		cb(isValid);
	};

	React.useEffect(() => {
		if (state.email) {
			handleEmailValidity(state.email, valid =>
				handleStateUpdate({ isEmailValid: valid })
			);
		}
	}, [state.email]);

	return (
		<>
			<SEO />
			<Layout handleSignupRedirect={handleSignupRedirect}>
				<Cta
					handleStateUpdate={handleStateUpdate}
					state={state}
					handleSubmit={handleSubmit}
				/>
				<Features />
				<Testimonials handleSignupRedirect={handleSignupRedirect} />
				<Partners />
			</Layout>
		</>
	);
};

export default IndexPage;
